import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VDialog, {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "max-width": "1300"
    },
    model: {
      value: _vm.dialogOpen,
      callback: function ($$v) {
        _vm.dialogOpen = $$v;
      },
      expression: "dialogOpen"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "mx-0 px-0 my-0 py-0",
    attrs: {
      "color": "primary",
      "fixed": "",
      "top": "",
      "dark": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Neue Adresse hinzufügen")])], 1)], 1), _c(VCardText, [_c(VTabs, {
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.dialogTab,
      callback: function ($$v) {
        _vm.dialogTab = $$v;
      },
      expression: "dialogTab"
    }
  }, [_c(VTab, {
    key: "person"
  }, [_vm._v(" Person ")]), _c(VTab, {
    key: "nonPerson"
  }, [_vm._v(" Nichtperson ")])], 1), _c(VTabsItems, {
    staticClass: "pt-3",
    model: {
      value: _vm.dialogTab,
      callback: function ($$v) {
        _vm.dialogTab = $$v;
      },
      expression: "dialogTab"
    }
  }, [_c(VTabItem, {
    key: "Person"
  }, [_c('AddPersonTab')], 1), _c(VTabItem, {
    key: "Nichtperson"
  }, [_c('AddNonPersonTab')], 1)], 1)], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "success",
      "disabled": !_vm.isValid()
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" Speichern ")]), _c(VBtn, {
    on: {
      "click": _vm.test
    }
  }, [_vm._v("Debug")]), _c(VBtn, {
    on: {
      "click": _vm.setValuesToTestValues
    }
  }, [_vm._v("Fill")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };