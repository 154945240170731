import { mapState } from 'vuex';
import AddPersonTab from '@/components/crm/addresses/addAddress/addPerson/AddPersonTab.vue';
import AddNonPersonTab from '@/components/crm/addresses/addAddress/addNonPerson/AddNonPersonTab.vue';
export default {
  components: {
    AddPersonTab,
    AddNonPersonTab
  },
  data: () => ({
    dialogOpen: false,
    dialogTab: null
  }),
  computed: {
    ...mapState({
      person: state => state.crmAddresses.newPerson,
      nonPerson: state => state.crmAddresses.newNonPerson,
      patientOptions: state => state.crmAddresses.patientOptions,
      validAddress: state => state.crmAddresses.validAddress,
      validPatient: state => state.crmAddresses.validPatient,
      validRelative: state => state.crmAddresses.validRelative,
      validEmployee: state => state.crmAddresses.validEmployee,
      validDoctor: state => state.crmAddresses.validDoctor,
      validGeneralInfo: state => state.crmAddresses.validGeneralInfo,
      validCompany: state => state.crmAddresses.validCompany
    })
  },
  methods: {
    test() {
      console.log(JSON.stringify(this.person));
      console.log(JSON.stringify(this.nonPerson));
      console.log('Validity: ' + this.isValid());
      console.log('Address: ' + this.validAddress);
      console.log('Patient: ' + this.validPatient + ' ' + this.person.isPatient);
      console.log('Relative: ' + this.validRelative + ' ' + this.person.isRelative);
      console.log('Doktor: ' + this.validDoctor + ' ' + this.person.isDoctor);
      console.log('Company: ' + this.validCompany + ' ' + this.nonPerson.isCompany);
    },
    toggleDialog() {
      this.dialogOpen = !this.dialogOpen;
    },
    cancel() {
      this.dialogOpen = false;
      this.resetValuesToDefault();
    },
    save() {
      if (this.dialogTab == 0) {
        //--> person
        this.savePerson();
      } else if (this.dialogTab == 1) {
        //--> non-person
        this.saveNonPerson();
      }
      this.resetValuesToDefault();
    },
    savePerson() {
      if (this.person.isPatient) {
        //map healthInsurance company to its key
        this.person.patient.healthInsuranceCompany = {
          lfdnr: this.person.patient.healthInsuranceCompany.key.lfdnr,
          firma: this.person.patient.healthInsuranceCompany.key.firma
        };
        if (this.person.isRelative) {
          this.person.patient.mainInsuranceData.healthInsuranceCompany = {
            lfdnr: this.person.patient.mainInsuranceData.healthInsuranceCompany.key.lfdnr,
            firma: this.person.patient.mainInsuranceData.healthInsuranceCompany.key.firma
          };
        }
        this.person.patient.svnr = parseInt(this.person.patient.svnr);
        this.person.patient.familyDoctor = this.person.patient.familyDoctor.key;
      }
      this.$store.dispatch('saveNewPerson', this.person).then(() => {
        this.resetValuesToDefault();
        this.dialogOpen = false;
      });
    },
    saveNonPerson() {
      this.$store.dispatch('saveNewNonPerson', this.nonPerson).then(() => {
        this.resetValuesToDefault();
        this.dialogOpen = false;
      });
    },
    resetValuesToDefault() {
      this.$store.commit('resetNewPerson');
      this.$store.commit('resetNewNonPerson');
    },
    setValuesToTestValues() {
      this.$store.commit('setValuesToTestValues');
    },
    isValidPerson() {
      return this.validAddress && (this.person.isPatient && this.validPatient || !this.person.isPatient) && (this.person.isRelative && this.validRelative || !this.person.isRelative) && (this.person.isEmployee && this.validEmployee || !this.person.isEmployee) && (this.person.isDoctor && this.validDoctor || !this.person.isDoctor) && (this.person.isConsumer || this.person.isAdultRepresentation || this.person.isPatient || this.person.isEmployee || this.person.isDoctor);
    },
    isValidNonPerson() {
      return (this.nonPerson.isCompany || this.nonPerson.isAdultRepresentation || this.nonPerson.isResidence) && this.validGeneralInfo && (this.nonPerson.isCompany && this.validCompany || !this.nonPerson.isCompany);
    },
    isValid() {
      if (this.dialogTab == 0) {
        return this.isValidPerson();
      } else if (this.dialogTab == 1) {
        return this.isValidNonPerson();
      }
    },
    loadDefaults() {
      this.$store.dispatch('loadAddAddressOptions');
    }
  },
  mounted() {
    this.loadDefaults();
  }
};