import SearchAddress from '@/components/crm/addresses/SearchAddress.vue';
export default {
  components: {
    SearchAddress
  },
  computed: {
    // This is needed to not show the search address component if the sub routes are accessed
    isRimIndexSite() {
      return this.$route.name == 'CRM';
    }
  }
};